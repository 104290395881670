<template>
  <div class="register-success-form">
    <v-card class="mb-8 pa-4" outlined>
      <v-card-title>
        <h4 class="primary--text">
          {{ $t('register.step5.header') }}
        </h4>
      </v-card-title>
      <v-card-text class="body-1 pt-3">
        <p>{{ $t('register.step5.paragraph1') }}</p>
        <p>{{ $t('register.step5.paragraph2') }} <strong>{{ companyId }}</strong></p>
        <p>{{ $t('register.step5.paragraph3') }}</p>
        <p>{{ $t('register.step5.paragraph4') }} <a href="mailto:spedimo@spedimo.eu">spedimo@spedimo.eu</a></p>

        <p>{{ $t('register.step5.paragraph5') }} <a href="www.spedimo.eu">www.spedimo.eu</a></p>
        <br>
        <p>{{ $t('register.step5.paragraph6') }}</p>
        Spedimo
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { contactPhoneNumber, contactMail } from '@/utils/helpers'

export default {
  name: 'RegisterSuccessForm',
  props: {
    companyId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      contactPhoneNumber,
      contactMail
    }
  }
}
</script>
