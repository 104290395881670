<template>
  <div class="register-company-form">
    <v-card class="mb-8 pa-4" outlined>
      <v-card-title>
        <h4 class="primary--text">{{ $t('register.step1.header') }}</h4>
      </v-card-title>
      <v-card-text class="pt-5">
        <v-form
          v-model="valid"
          ref="registerCompanyForm"
        >
          <v-row no-gutters>
            <v-col cols="12" md="4">
              <label for="VatNumber">{{ $t('register.step1.taxNumber') }}</label>
              <v-text-field
                v-model="forms.VatNumber"
                id="VatNumber"
                outlined
                required
                validate-on-blur
                dense
                single-line
                :rules="rules.nip"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-12">
            <v-col cols="12">
              <v-btn
                color="primary"
                :disabled="isGusEnabled"
                :loading="companyDataLoading"
                @click="fetchComapnyDataFromGUS"
                depressed
              >
                {{ $t('register.step1.downloadDataWithGus') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="8">
              <label for="CompanyName">{{ $t('register.step1.companyName') }}</label>
              <v-text-field
                v-model="forms.CompanyName"
                id="CompanyName"
                outlined
                required
                validate-on-blur
                dense
                single-line
                :rules="rules.companyName"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="8">
              <label for="Street">{{ $t('register.step1.street') }}</label>
              <v-text-field
                v-model="forms.Street"
                id="Street"
                outlined
                required
                validate-on-blur
                dense
                single-line
                :rules="rules.street"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="4">
              <label for="ZipCode">{{ $t('register.step1.zipCode') }}</label>
              <v-text-field
                v-model="forms.ZipCode"
                id="ZipCode"
                outlined
                required
                validate-on-blur
                dense
                single-line
                :rules="rules.zipCode"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="4">
              <label for="City">{{ $t('register.step1.city') }}</label>
              <v-text-field
                v-model="forms.City"
                id="City"
                outlined
                required
                validate-on-blur
                dense
                single-line
                :rules="rules.city"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="2" md="2" lg="2" xl="1">
              <label for="Country">{{ $t('register.step1.country') }}</label>
              <CountryFlagSelect v-model="forms.Country" :rules="rules.country"/>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-row no-gutters class="mb-8">
      <v-col cols="12" class="d-flex justify-center">
        <v-btn
          color="secondary"
          outlined
          class="mr-2"
          disabled
        >
          {{ $t('register.prev') }}
        </v-btn>
        <v-btn color="primary" @click="next" depressed>
          {{ $t('register.next') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import services from '@/services'
import { isVatNumberValid, maxCharInZipCode } from '@/utils/helpers'
import CountryFlagSelect from '@/components/CountryFlagSelect'

export default {
  name: 'RegisterCompanyForm',
  components: {
    CountryFlagSelect
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        VatNumber: '',
        CompanyName: '',
        Street: '',
        ZipCode: '',
        City: '',
        Country: ''
      })
    }
  },
  computed: {
    isGusEnabled () {
      const regex = /^[0-9]{8,14}$/
      const matches = regex.test(this.forms.VatNumber)
      return !matches || this.companyDataLoading
    }
  },
  data () {
    return {
      companyDataLoading: false,
      forms: {
        VatNumber: '',
        CompanyName: '',
        Street: '',
        ZipCode: '',
        City: '',
        Country: ''
      },
      rules: {
        nip: [
          v => (!!v && !!v.trim()) || this.$t('validation.fieldRequired'),
          v => (!!v && (!!v && isVatNumberValid(v) && !!v.trim())) || this.$t('validation.nipInvalidField')
        ],
        companyName: [
          v => (!!v && !!v.trim()) || this.$t('validation.fieldRequired')
        ],
        street: [
          v => (!!v && !!v.trim()) || this.$t('validation.fieldRequired')
        ],
        zipCode: [
          v => (!!v && !!v.trim()) || this.$t('validation.fieldRequired'),
          maxCharInZipCode
        ],
        city: [
          v => (!!v && !!v.trim()) || this.$t('validation.fieldRequired')
        ],
        country: [
          v => (!!v && !!v.trim()) || this.$t('validation.fieldRequired')
        ]
      },
      valid: true
    }
  },
  mounted () {
    if (this.value && this.value.VatNumber && this.value.VatNumber.length > 0) {
      this.forms = this.value
      this.$nextTick(() => {
        this.$refs.registerCompanyForm.validate()
      })
    }
  },
  methods: {
    next () {
      this.$refs.registerCompanyForm.validate()
      if (!this.valid) {
        return
      }
      this.$emit('next', this.forms)
    },
    async fetchComapnyDataFromGUS () {
      this.companyDataLoading = true
      try {
        const { data } = await services.get('ExternalApi/GetGusByNip', {
          nip: this.forms.VatNumber
        })
        this.forms = {
          ...this.forms,
          CompanyName: data.name,
          Country: 'PL',
          City: data.city,
          ZipCode: data.postalCode,
          Street: `${data.street} ${data.streetNumber}`
        }
        this.$nextTick(() => {
          this.$refs.registerCompanyForm.validate()
        })
      } catch (e) {
        await this.$store.dispatch('setErrorNotification', this.$t('register.nipError'))
      }
      this.companyDataLoading = false
    }
  }
}
</script>
