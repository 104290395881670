<template>
  <div class="register-personal-form">
    <v-card class="mb-8 pa-4" outlined>
      <v-card-title>
        <h4 class="primary--text">{{ $t('register.step2.header') }}</h4>
      </v-card-title>
      <v-card-text class="pt-5">
        <v-form
          v-model="valid"
          ref="registerPersonalForm"
        >
          <v-row no-gutters>
            <v-col cols="12" md="8">
              <label for="userName">{{ $t('register.step2.userName') }}</label>
              <v-text-field
                v-model="forms.userName"
                id="userName"
                outlined
                required
                validate-on-blur
                dense
                single-line
                :rules="rules.userName"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="8">
              <label for="surname">{{ $t('register.step2.surname') }}</label>
              <v-text-field
                v-model="forms.surname"
                id="surname"
                outlined
                required
                validate-on-blur
                dense
                single-line
                :rules="rules.surname"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="8">
              <label for="phoneNumber">{{ $t('register.step2.phone') }}</label>
              <v-text-field
                v-model="forms.phoneNumber"
                id="phoneNumber"
                outlined
                required
                validate-on-blur
                dense
                single-line
                :rules="rules.phoneNumber"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="8">
              <label for="email">{{ $t('register.step2.email') }}</label>
              <v-text-field
                v-model="forms.email"
                outlined
                required
                validate-on-blur
                dense
                single-line
                :rules="rules.email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="4">
              <label for="email">{{ $t('register.step2.language') }}</label>
              <v-select
                v-model="forms.language"
                :items="languages"
                item-text="label"
                item-value="value"
                id="email"
                outlined
                dense
                single-line
                :rules="rules.language"
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-row no-gutters class="mb-8">
      <v-col cols="12" class="d-flex justify-center">
        <v-btn
          color="secondary"
          outlined
          class="mr-2"
          @click="$emit('prev')"
        >
          {{ $t('register.prev') }}
        </v-btn>
        <v-btn
          color="primary"
          @click="next"
          depressed
        >
          {{ $t('register.next') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import services from '@/services'
import { maxCharInPhoneNumber } from '@/utils/helpers'

export default {
  name: 'RegisterPersonalForm',
  props: {
    value: {
      type: Object,
      default: () => ({
        userName: '',
        surname: '',
        email: '',
        phoneNumber: '',
        language: ''
      })
    }
  },
  data () {
    return {
      languages: [],
      forms: {
        userName: '',
        surname: '',
        email: '',
        phoneNumber: '',
        language: ''
      },
      rules: {
        userName: [
          v => (!!v && !!v.trim()) || this.$t('validation.fieldRequired'),
          v => v.length <= 15 || this.$t('validation.maxCountChar', { count: 15 }),
          v => {
            const regex = /^[A-Za-z żźćńółęąśŻŹĆĄŚĘŁÓŃ-]+$/
            const matches = regex.test(v)
            return matches || this.$t('validation.invalidField')
          }
        ],
        surname: [
          v => (!!v && !!v.trim()) || this.$t('validation.fieldRequired'),
          v => v.length <= 25 || this.$t('validation.maxCountChar', { count: 25 }),
          v => {
            const regex = /^[A-Za-z żźćńółęąśŻŹĆĄŚĘŁÓŃ-]+$/
            const matches = regex.test(v)
            return matches || this.$t('validation.invalidField')
          }
        ],
        phoneNumber: [
          v => (!!v && !!v.trim()) || this.$t('validation.fieldRequired'),
          maxCharInPhoneNumber
        ],
        email: [
          v => (!!v && !!v.trim()) || this.$t('validation.emailRequired'),
          v => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || this.$t('validation.emailInvalidFormat')
        ],
        language: [
          v => (!!v && !!v.trim()) || this.$t('validation.fieldRequired')
        ]
      },
      valid: true
    }
  },
  async created () {
    await this.fetchLanguages()
  },
  mounted () {
    if (this.value && this.value.userName && this.value.userName.length > 0) {
      this.forms = this.value
      this.$nextTick(() => {
        this.$refs.registerPersonalForm.validate()
      })
    }
  },
  methods: {
    next () {
      this.$refs.registerPersonalForm.validate()
      if (!this.valid) {
        return
      }
      this.$emit('next', this.forms)
    },
    async fetchLanguages () {
      const { data } = await services.get('Frontend/GetAvailableLanguages')
      this.languages = data.map(language => {
        return {
          value: language,
          label: this.$t(`languages.${language}`)
        }
      })
    }
  }
}
</script>
